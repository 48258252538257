<template>
    <v-container>
        <form slot="leftContainer" autocomplete="nope" @submit.prevent="getReporteSelect" class="my-2">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>GENERAR REPORTE </b>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-row dense align="center">
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="form.idSede"
                                v-validate="'required'"
                                :items="_sedes"
                                item-text="detail"
                                item-value="id"
                                :error-messages="errors.collect('form.idSede')"
                                label="SEDE"
                                data-vv-name="form.idSede"
                                data-vv-as="Sede"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3" v-if="form.idReport != 7">
                            <v-menu
                                dense
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.fecha"
                                        label="FECHA"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        data-vv-name="form.fecha"
                                        data-vv-as="fecha"
                                        :error-messages="errors.collect('form.fecha')"
                                        v-on="on"
                                        dense
                                    />
                                </template>
                                <v-date-picker
                                    v-model="form.fecha"
                                    no-title
                                    locale="es"
                                    type="month"
                                    class="mt-4"
                                    min="2019-06"
                                    max="2030-12"
                                    @input="menu = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3" v-if="form.idReport == 7">
                            <v-menu
                                dense
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="form.fecha"
                                        label="MES"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        data-vv-name="form.fecha"
                                        data-vv-as="fecha"
                                        :error-messages="errors.collect('form.fecha')"
                                        v-on="on"
                                        dense
                                    />
                                </template>
                                <v-date-picker v-model="form.fecha" no-title locale="es" class="mt-4" @input="menu = false" />
                            </v-menu>
                        </v-col>
                        <v-col v-if="watchModel" cols="12" sm="3">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="form.idReport"
                                item-text="text"
                                item-value="id"
                                :items="itemsReporte"
                                label="TIPO REPORTE"
                            />
                        </v-col>
                        <v-col cols="12" sm="3" v-if="form.idReport == 7">
                            <v-autocomplete
                                outlined
                                dense
                                v-validate="'required'"
                                v-model="form.idColaborador"
                                item-text="name"
                                item-value="id"
                                :items="_listaColaborador"
                                label="COLABORADORES"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn type="submit" :loading="loading" dark color="primary" block :disabled="!form.fecha">
                                <b>{{ 'BUSCAR' }}</b>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </form>
    </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { exportXLSX } from '@/utils'

import { format } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            menu: false,
            loadingDownload: false,
            fecha: format(new Date(), 'yyyy-MM'),
            loading: false,

            form: DefaultForm.formReport(),
            dialog: false,
            headers: [
                { text: 'FECHA CONSUMIDO', value: 'fecha' },
                { text: 'TICKET', value: 'ticket' },
                { text: 'TIPO VENTA', value: 'tipoVenta' },
                { text: 'ROOM', value: 'room' },
                { text: 'DETALLE', value: 'detalle' },
                { text: 'MONTO', value: 'monto' }
            ]
        }
    },

    computed: {
        ...mapGetters('sedes', ['_sedes']),
        ...mapState('utilities', ['itemsTypeReport', 'tipoBancoDestino', 'typeReport']),
        ...mapState('asistencia', ['listaColaborador']),
        ...mapState('reporte', ['reporteVentas', 'wachtReport']),
        listItem() {
            console.log('this.reporteVentas', this.reporteVentas)
            return this.reporteVentas
                .sort(function (a, b) {
                    return new Date(b.fecha) - new Date(a.fecha)
                })
                .reverse()
        },
        itemsReporte() {
            let listItems
            switch (this.wachtReport) {
                case 5:
                case 6:
                    listItems = this.itemsTypeReport
                    break
                default:
                    listItems = this.typeReport
                    break
            }
            return listItems
        },
        watchModel() {
            let status = false
            switch (this.wachtReport) {
                case 5:
                case 6:
                    status = true
                    break

                default:
                    break
            }
            return status
        },
        _listaColaborador() {
            let items = []
            console.log(' this.listaColaborador', this.listaColaborador)
            this.listaColaborador.map((x) => {
                if (x.id_role != 5 && x.isLogin == 1 && x.status == 1) {
                    items.push({
                        ...x,
                        name: `${x.nombres} , ${x.apellidos}`.toUpperCase()
                    })
                }
            })
            console.log('items', items)
            return items
        }
    },

    async created() {
        this.form.idReport = 7
        await Promise.all([
            this.$store.dispatch('bussines/getAllBussines'),
            this.$store.dispatch('asistencia/getListaColaborador'),
            this.$store.dispatch('sedes/getAllSedes'),
            this.$store.dispatch('ventas/getReservas', {})
        ])
    },

    methods: {
        descargarArchivo() {
            this.loadingDownload = false
            const headers = ['fecha', 'ticket', 'tipoVenta', 'room', 'detalle', 'monto']
            const filename = `Reporte ${this.form.fecha}`
            exportXLSX({ headers, campos: headers, arrayData: this.reporteVentas }, { filename })
            this.loadingDownload = false
        },
        async reporteTipoVenta() {
            await this.$store.dispatch('reporte/reporteTipoVenta', { ...this.form })
        },

        async getReporte() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    try {
                        this.loading = true

                        const sede = this._sedes.find((x) => x.id === this.form.id)
                        const body = {
                            ...this.form,
                            ...sede,
                            fecha: this.form.fecha
                        }
                        await this.$store.dispatch('reporte/reporteMesSede', body)

                        this.form = {
                            id: 2,
                            detalle: null,
                            monto: null
                        }

                        this.loading = false
                    } catch (error) {
                        //console.log('error: ', error)
                        this.loading = false
                    }
                }
            })
        },
        async getReporteSelect() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    try {
                        let filterVentas
                        let filterCotizaciones
                        if (this.form.idReport == 7) {
                            const promises = await Promise.all([
                                this.$store.dispatch('ventas/getVentasByMonth', {
                                    idColaborador: this.form.idColaborador,
                                    date: this.form.fecha
                                }),
                                this.$store.dispatch('cotizaciones/getCotizacionesByMonth', {
                                    idColaborador: this.form.idColaborador,
                                    date: this.form.fecha
                                })
                            ])
                            filterVentas = promises[0]
                            filterCotizaciones = promises[1]
                        }
                        console.log('filterVentas', filterVentas)
                        console.log('filterCotizaciones', filterCotizaciones)
                        const reporte = this.itemsTypeReport.find((x) => x.id === this.form.idReport)
                        await this.$store.dispatch(reporte.nameDispatch, {
                            ...this.form,
                            sede: this._sedes,
                            colaborador: this._listaColaborador,
                            filterVentas,
                            filterCotizaciones
                        })
                        this.form = DefaultForm.formReport()
                        this.form.idReport = 7
                        this.loading = false
                    } catch (error) {
                        //console.log('error: ', error)
                        this.loading = false
                    }
                }
            })
        }
    }
}
</script>
